//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActionButtons from '@/components/Profile/Settings/ActionButtons'
export default {
    props: ['dataset'],
    data() {
        return {
            unhs: this.dataset.unhs,
            handicap: this.dataset.handicap,
        }
    },
    computed: {

        // The script to determine whether to unlock the save button.
        enableSave() {
            if (this.unhs != this.dataset.unhs) {
                return false;
            }

            if (this.handicap != this.dataset.handicap) {
                return false;
            }
    
            return true;
        }
    },
    methods: {
        reset() {
            this.unhs = this.dataset.unhs
            this.handicap = this.dataset.handicap
        }
    },
    components: {
        ActionButtons
    }
}
